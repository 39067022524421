<template>
  <div class="login-container">
    <el-row :gutter="60" style="margin-left: 0!important;margin-right: 0!important;">
      <el-col :span="12">
        <img src="@/assets/images/login/bg.png" alt="" style="width: 100%;height: 100%;">
      </el-col>

      <el-col :span="12">
        <div style="margin-top: 30px;">
          <div style="width: 100px;height: 100px;margin: auto;">
            <img src="@/assets/images/login/logo.png" alt="" style="width: 100%;height: 100%;">
          </div>

          <div style="width: 503px;height: 38px;margin: auto;margin-top: 30px;">
            <img src="@/assets/images/login/title.png" alt="" style="width: 100%;height: 100%;">
          </div>

          <el-row :gutter="15" style="text-align: center;margin-top: 50px;display: flex;justify-content: space-evenly;">
            <el-col :span="15">
              <el-form ref="form" :model="form" :rules="rules" label-width="100px"  @keyup.enter="handleSubmit('formInline')">
                <!--登录-->
                <div>
                  <div style="margin-top: 20px;">
                    <el-input
                        placeholder="请输入账号"
                        v-model="form.account"
                        clearable>
                    </el-input>
                  </div>
                  <div style="margin-top: 20px;">
                    <el-form-item label-width="0" prop="password">
                      <el-input
                          placeholder="请输入密码"
                          v-model="form.password"
                          clearable>
                      </el-input>
                    </el-form-item>
                  </div>
                  <div style="margin-top: 60px;">
                    <el-button style="background: #FEA837!important;border-color: #FEA837!important;color: #FFFFFF!important;width: 80%!important;" @click="handleSubmit()">登录</el-button>
                  </div>
                </div>
              </el-form>

            </el-col>
          </el-row>

        </div>
      </el-col>

    </el-row>
  </div>
</template>

<script>

import {
  superLoginApi, systemadmin_info
} from '@/api/account'

//引入 websocket
import websocket from '@/config/websocket'

import { setCookies } from '@/utils/cookies'
import Config from '@/config'


export default {
  name: 'new_login',
  data () {
    return {
      form: {
        account: 'shenhe',
        password: '123456',
      },
      rules: {
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
      },

    }
  },
  components: {},
  // 支柱道具属性
  props: {},
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted () {
     window.addEventListener("keyup", this.handleEnterKey);
  },
  // 方法集合
  methods: {
    handleEnterKey(event) {
      console.log(666);
      if (event.key === "Enter") {
        this.handleSubmit("formInline");
      }
    },
    // 提交表单
    handleSubmit () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const msg = this.$Message.loading({
            content: '登录中...',
            duration: 0
          });
          superLoginApi(this.form).then(async (res) => {
            msg()
            let data = res.data
            // console.log('data',data);
            let expires = this.getExpiresTime(data.expires_time)
            setCookies(Config.tokenStoreName, data.token, expires)
            setCookies('expires_time', data.expires_time, expires)

            // 登录人身份
            localStorage.setItem('identity_rank',data.identity_rank)

            this.$store.commit('admin/SET_USERSINFO', data.platform_info)
            this.$store.commit('admin/SET_UNIQUEAUTH', data.unique_auth)

            this.$store.commit('admin/SET_MENUS', data.menus)

            // const query = this.$route.query
            // const path = query && query.from ? query.from : '/admin/'
            // return this.$router.replace({
            //   path: this.findFirstNonNullChildren(res.data.menus).path || '/screen'
            // })
            systemadmin_info().then(res => {
              localStorage.setItem('userInfo',JSON.stringify(res.data.platform_info));
              // 登录标识
              let data = {
                isLogin: true
              }
              this.$addStorageEvent(1, "isLogin", JSON.stringify(data))

              // 默认进入不显示弹窗
              let data1 = {
                showPhone: false
              }
              this.$addStorageEvent(1, "showPhone", JSON.stringify(data1))
              // 默认空手机号
              let params = {
                phone: ''
              }
              this.$addStorageEvent(1, "phone", JSON.stringify(params))

              // 默认订单号为空
              let orderid = {
                orderid: ''
              }
              this.$addStorageEvent(1, "orderid", JSON.stringify(orderid))

              // 默认呼出类型为空
              let teltype = {
                tel_type: ''
              }
              this.$addStorageEvent(1, "tel_type", JSON.stringify(teltype))

              return this.$router.push({ path: res.data.menus[0].children[0].path })
              // 开启websocket
              // websocket.openSocket();
            })
            // .catch(err => {
            //   console.log(err)
            // })
          }).catch(error => {
            console.log(error)
            this.$Message.error(error.msg)
          })
        } else {
          this.$Message.error('请确保必填项不为空哦! (oﾟωﾟo)')
        }
      })
    },
    findFirstNonNullChildren (arr) {
      // 如果数组为空，返回null
      if (!arr || arr.length === 0) {
        return null
      }
      // 找到第一个对象
      const firstObj = arr[0]
      // 如果第一个对象没有children属性，返回该对象
      if (!firstObj.children) {
        return firstObj
      }

      // 如果第一个对象的children属性是数组，
      // 递归查找children属性中的第一个非null children属性
      if (Array.isArray(firstObj.children)) {
        return this.findFirstNonNullChildren(firstObj.children)
      }
      // 如果数组中没有非null children属性，返回null
      return null
    },
    getExpiresTime (expiresTime) {
      let nowTimeNum = Math.round(new Date() / 1000)
      let expiresTimeNum = expiresTime - nowTimeNum
      return parseInt(parseFloat(parseFloat(expiresTimeNum / 60) / 60) / 24)
    },

    // 选项卡切换事件
    handleClick(tab, event) {
      console.log(tab);
      this.activeName = tab.name;
      if (tab.label === '手机号登录') {
        this.rules.phone = [
          { required: true, message: '手机号必填', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur' }
        ]
        this.rules.code = [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      }else {
        this.rules.phone = [];
        this.rules.code = [];
      }

    },

  },
  // 生命周期 - 更新之前
  beforeUpdate () {
  },
  // 生命周期 - 更新之后
  updated () {
  },
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate () {
  },
  // 生命周期 - 挂载之前
  beforeMount () {
  },
  // 生命周期 - 销毁之前
  beforeDestroy () {
  },
  // 生命周期 - 销毁完成
  destroyed () {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated () {
  },
  // 离开的时候触发
  deactivated () {
  }
}
</script>

<style scoped lang="scss">
.login-container {
  //position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100vh;
  background: #FFFFFF !important;

  .left {
    position: relative;
    width: 515px;
    height: 460px;
    z-index: 1;
    img {
      width: 100%;
      //height: 100%;
    }
  }

  .page-account {
    position: relative;
    width: 415px;
    height: auto;
    z-index: 1;

    &__logo {
      font-size: 30px;
      text-align: center;
    }
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}

.bg1{
  background-image: url("../../assets/images/login/siji.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: left;
  margin-top: 30px;
  padding: 50px 20px 50px 20px;
  cursor: pointer;
}
.bg2{
  background-image: url("../../assets/images/login/fuwushang.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: left;
  margin-top: 30px;
  padding: 50px 20px 50px 20px;
  cursor: pointer;
}
</style>
