import Setting from '@/config/setting'
import {getCookies, removeCookies} from '@/utils/cookies'
import Bus from '@/utils/bus'
import Config from "@/config/index";
import router from "@/router";
import settings from "@/config/index";

import { Message } from 'element-ui';

var that = this;

var socket = '';

const webSocket = {
    userId: '',
    isReconnect: false, //是否重新链接开关
    reconnectTimer: null, //重新连接的计时器
    mentalityTimer: null, //心跳倒计时

    socket:'',

    //开启socket
    openSocket() {
        console.log('websocket准备连接');
        //判断是否登录状态
        if(getCookies(Config.tokenStoreName) != false){
            // console.log('登录状态');
            socket = new WebSocket(Setting.wsAdminSocketUrl);
            // 监听socket连接
            socket.onopen = this.open;
            // 监听socket错误信息
            socket.onerror = this.error;
            // 监听socket消息
            socket.onmessage = this.getMessage;
        }else {
            console.log('请登录！')
        }
    },

    open(){
        // console.log(socket);
        // console.log("socket连接成功");
        let data = {
            type:'login',
            data: getCookies(Config.tokenStoreName)
        }
        // 发送消息
        socket.send(JSON.stringify(data));

        console.log(webSocket)

        // 开启心跳
        // setInterval(() => {
        //     this.start();
        //     console.log('每次隔3秒进行重新握手')
        // },3000)

    },
    error(e){
        console.log('连接错误')
        console.log(e)
        // 链接错误重新链接
        let data = {
            type:'reconnection',
        }
        // 发送消息
        socket.send(JSON.stringify(data));
    },
    //接受socket服务器的信息
    getMessage: function (msg) {
        // console.log(typeof(msg.data))
        let str = JSON.parse(msg.data)
        console.log(str)
        // NEW_ORDER 您有新的订单
        // DELAY_ORDER
        // AGING 时效
        // COMPLAINT 投诉
        // REMINDER 催单
        // ABNORMAL 异常通知
        // content 内容
        if(str.type == 'NEW_ORDER'){
            Bus.$emit('showMessage',str);
        }else if(str.type == 'RESCUE_FAILURE'){
            Bus.$emit('showMessage',str);
        }else if(str.type == 'AGING'){
            Bus.$emit('showMessage',str);
        }else if(str.type == 'COMPLAINT'){
            Bus.$emit('showMessage',str);
        }else if(str.type == 'REMINDER'){
            Bus.$emit('showMessage',str);
        }else if(str.type == 'ABNORMAL'){
            Bus.$emit('showMessage',str);
        }else if(str.type == 'DELAY_ORDER'){
            Bus.$emit('showMessage',str);
        }
        else if(str.type == 'handshake'){
            // 收到重连消息重新发送消息
            let data = {
                type:'reconnection',
            }
            // 发送消息
            socket.send(JSON.stringify(data));
        } else if(str.type == 'timeout'){
            // superman 超级管理员
            if(localStorage.getItem('identity_rank') == 'superman'){
                Message.success('您已成功退出')
                removeCookies(settings.tokenStoreName)
                removeCookies('expires_time')
                removeCookies('uuid')
                // 清空本地缓存
                localStorage.clear()
                // 清空sessionStorage
                sessionStorage.clear()
                // 跳转登录页面
                setTimeout(() => {
                    // this.$router.push({ path: '/login' })
                    router.push('/super_login');
                }, 1500)
            }else {
                Message.success('您已成功退出')
                removeCookies(settings.tokenStoreName)
                removeCookies('expires_time')
                removeCookies('uuid')
                // 清空本地缓存
                localStorage.clear()
                // 清空sessionStorage
                sessionStorage.clear()
                // 跳转登录页面
                setTimeout(() => {
                    // this.$router.push({ path: '/login' })
                    router.push('/login');
                }, 1500)
            }


        }
    },
    //socket重连
    reconnect(res) {
        console.log(res, '重启信息')
    },
    // 发送消息给被连接的服务端
    start () {
        let data = {
            type:'login',
            data: getCookies(Config.tokenStoreName)
        }
        // 发送消息
        socket.send(JSON.stringify(data));
    },
    //重置心跳
    reset() {
        // console.log('重置心跳')
        //清除时间
        // clearTimeout(this.mentalityTimer);
        //重启心跳
        // this.start();
    },
    //关闭socket
    closeSocket(e) {
        console.log('连接关闭');
        this.socket.onclose = this.close
    },
}
export default webSocket
